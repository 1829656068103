import styled from "styled-components";
import Paper from "@material-ui/core/Paper";

export const ContainerSearch = styled.div`
  display: flex;
`;

export const PaperStyle = styled(Paper)`
  .div {
    padding: 4px 12px;
    h1 {
      font-weight: bold;
      color: rgba(80, 80, 80, 0.91);
      font-size: 30px;
      margin-bottom: 10px;
    }
  }
`;

export const Pesquisa = styled.div`
  margin-bottom: 20px;
`;

export const FormAuto = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  label {
    padding-right: 10px;
  }

  .index {
    z-index: 300;
    display: flex;
    flex-direction: column;
  }
  .index2 {
    z-index: 290;
    display: flex;
    flex-direction: column;
  }
  input {
    height: calc(1em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    position: relative;
    width: 26rem;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
  textarea {
    height: calc(5.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    position: relative;
    width: 26rem;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
  width: 150px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;

  input {
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;

    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    position: left;
    width: 250px;
    min-width: 10rem;
  }
`;


export const FormEditValue = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 0px;

  input {
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;

    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    position: left;
    width: 250px;
    min-width: 10rem;
  }
`;
export const Input = styled.input`
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  position: relative;
  max-width: 80%;
`;

export const Select = styled.select`
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  position: relative;
  width: 80%;
`;

export const ButtonStyled = styled.button`
  padding: 10px;
  margin-left: 20px;
  border: 0 none;
  border-radius: 5px;
  background-color: #00acc1;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  transition: 0.4s;
  cursor: pointer;
  position: static;
  width: 200px;
  @media (max-width: 768px) {
    width: 200px;
    margin-left: 0px;
    margin-top: 10px;
  }
`;
export const ButtonStyledEdit = styled.button`
  padding: 0px;
  margin-left: 0px;
  border: 0 none;
  border-radius: 5px;
  background-color: #00acc1;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  transition: 0.4s;
  cursor: pointer;
  position: static;
  width: 100px;
  @media (max-width: 768px) {
    width: 100px;
    margin-left: 0px;
    margin-top: 10px;
  }
`;

export const ButtonRequest = styled.button`
  margin-top: 0px;
  margin-left: 0px;

  padding: 0px;
  border: 0 none;
  border-radius: 5px;
  background-color: #00acc1;
  font-weight: bold;
  color: #fff;
  transition: 0.4s;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;
export const Div = styled.div`
  z-index: 3;
`;
