import styled from "styled-components";

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  .divCheck {
    display: flex;
    align-items: center;
    h6 {
      margin: 0;
      font-size: 14px;
      font-weight: bold;
      color: rgb(170, 170, 170);
      margin-right: 6px;
    }
  }

  .input {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    z-index:2;
    label {
      font-weight: bold;
    }
    input,
    select {
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      position: relative;
      width: 100%;
      z-index: 3;
    }
  }
`;

export const ButtonStyled = styled.button`
  margin-top: 40px;
  margin-left: 5px;
  width: 250px;
  padding: 10px;
  border: 0 none;
  background-color: #00acc1;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  transition: 0.4s;
  position:relative;
  cursor: pointer;
  &:hover {
    border-radius: 20px;
    opacity: 0.7;

  }
`;