// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import ContactsIcon from "@material-ui/icons/Contacts";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import AssessmentIcon from '@material-ui/icons/Assessment';
import ArrowRight from "@material-ui/icons/ArrowRight";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import Produtos from "views/Produtos/Produtos.js";
import Clientes from "views/Clientes/Clientes.js";
import Pedidos from "views/Pedidos/Pedidos.js";
import Carrinho from "views/Carrinho/Carrinho";
import Relatorios from "views/Relatorios/relatorios.js";


const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    key: "0",
    rtlName: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
    click: "open"
  },
  {
    path: "/table",
    name: "Clientes",
    key: "1",
    rtlName: "Clientes",
    icon: SupervisorAccountIcon,
    component: Clientes,
    layout: "/admin",
    click: "open"
  },
  {
    path: "/produtos",
    name: "Produtos",
    key: "2",
    rtlName: "Produtos",
    icon: ShoppingBasketIcon,
    component: Produtos,
    layout: "/admin",
    click: "open"
  },
  {
    path: "/carrinho",
    name: "Carrinho",
    key: "3",
    rtlName: "Carrinho",
    icon: AddShoppingCartIcon,
    component: Carrinho,
    layout: "/admin",
    click: "open"
  },
  {
    path: "/relatorios",
    name: "Relatórios",
    key: "5",
    rtlName: "Relatorio2",
    icon: AssessmentIcon,
    component: Relatorios,
    layout: "/admin",
    click: "open",
  },
  
  {
    path: "/pedidos",
    name: "Pedidos de Venda",
    key: "4",
    rtlName: "Pedidos",
    icon: ContactsIcon,
    component: Pedidos,
    layout: "/admin",
    click: "open"
  }

];

export default dashboardRoutes;
