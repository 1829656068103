import styled from "styled-components";

export const Container = styled.div`
  background: #fff;
  border-radius: 4px;
  margin: auto;
  padding: 40px 30px;
  width: 370px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
`;
