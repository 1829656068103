


import React, { useState, useEffect } from "react";
import { useSelector, connect } from "react-redux";
import DataTable from "components/Table/Table.js";
import ModalPedidosSQL from "components/Pedidos/ModalPedidosSQL";
import Grid from "@material-ui/core/Grid";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import axios from "axios";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Form, ButtonStyled } from "./styles";
import Autocomplete from "react-autocomplete";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Pesquisa } from "./styles";
import Pdf from "../../components/Pedidos/PDF";
import SendEmail from "../../components/Pedidos/ModalEmail";
import moment from "moment";
import { API } from "../../config/api"
import Paper from '@material-ui/core/Paper';
import Async from 'react-select/async';
import debounce from 'debounce-promise';
import Select from 'react-select';




const useStyles = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(1)
    }
}));

function createData(
    numeroSistema,
    status,
    nomeConta,
    emissao,
    previsao,
    referencia,
    valor,
    view,
    pdf,
    email
) {
    return {
        numeroSistema,
        status,
        nomeConta,
        emissao,
        previsao,
        referencia,
        valor,
        view,
        pdf,
        email
    };
}

const rowHead = [
    {
        title: "Pedido",
        field: "numeroSistema",
        headerStyle: {
            width: 55,
            whiteSpace: "nowrap",
            maxWidth: 60
        },
        cellStyle: {
            width: 45,
            maxWidth: 60,
            whiteSpace: "nowrap",
            textAlign: "left",
            fontSize: "12px"
        }
    },
    {
        title: "Status",
        field: "status",
        cellStyle: {
            fontSize: "12px"
        }
    },

    {
        title: "Cliente",
        field: "nomeConta",
        cellStyle: {
            fontSize: "5px",
            whiteSpace: "nowrap",
            textAlign: "left",
            fontSize: "12px"
        }
    },
    {
        title: "Emissão",
        field: "emissao",
        cellStyle: {
            fontSize: "12px"
        }
    },
    {
        title: "Previsão",
        field: "previsao",
        cellStyle: {
            fontSize: "12px"
        }
    },
    {
        title: "Referência",
        field: "referencia",
        headerStyle: {
            width: 100,
            whiteSpace: "nowrap",
            maxWidth: 100

        },

        cellStyle: {
            width: 100,
            maxWidth: 100,
            whiteSpace: "nowrap",
            textAlign: "left",
            fontSize: "12px"
        }
    },

    {
        title: "Valor",
        field: "valor",

        headerStyle: {
            width: 100,
            whiteSpace: "nowrap",
            textAlign: "right",
            maxWidth: 100

        },
        cellStyle: {
            width: 120,
            maxWidth: 120,
            whiteSpace: "nowrap",
            textAlign: "right",
            fontSize: "12px"
        }
    },
    {
        title: "Visualizar",
        field: "view",
        headerStyle: {
            width: 50,
            maxWidth: 70,
            padding: 1
        },
    },
    {
        title: "PDF",
        field: "pdf",
        headerStyle: {
            width: 10,
            maxWidth: 10,
            padding: 1,
            textAlign: "center"
        },
    },
    {
        title: "Email",
        field: "email",
        headerStyle: {
            width: 10,
            maxWidth: 10,
            padding: 1
        },
    }
];

function Pedidos() {
    const classes = useStyles();
    const token = sessionStorage.getItem("token");
    const email = sessionStorage.getItem("email");
    const perfil = sessionStorage.getItem("perfil");
    const id_user = sessionStorage.getItem("id");

    const clientes = JSON.parse(sessionStorage.getItem("clientes"));


    const [PanelOpen, setPanelOpen] = React.useState(false);

    const { register, getValues } = useForm();

    // AutoComplete
    const [auto, setAuto] = useState([]);
    const [dadosPedidos, setDadosPedidos] = useState();
    const [statusPedidos, setStatusPedidos] = useState([]);

    const [autoNomeSupervisor, setAutoNomeSupervisor] = useState('');
    const [idSupervisor, setIdSupervisor] = useState('');
    const [autoListSupervisor, setAutoListSupervisor] = useState([]);


    let value = "";
    let valueId = "";
    if (perfil === 'vendedor') {
        if (clientes) {
            value = clientes && clientes[0].vendedorPadrao === null ? "" : clientes[0].vendedorPadrao.nome;
            valueId = clientes && clientes[0].vendedorPadrao === null ? "" : clientes[0].vendedorPadrao.id;
        } else { value = ""; valueId = ""; }
    }
    const [valueAutoId, setValueAutoId] = useState(valueId);
    const [valueAutoNome, setValueAutoNome] = useState(value);



    const [autoCliente, setAutoCliente] = useState(clientes ? clientes : []);

    const [nomeCliente, setNomeCliente] = useState("");
    const [idCliente, setIdCliente] = useState("");
    const [totalPedidos, settotalPedidos] = useState(0);

    const [loading, setLloading] = useState(false);
    const [status_options, setStatus_options] = useState();


    const loadClients = async (inputValue, callback) => {
        try {
            var nome = encodeURIComponent(inputValue);
            var where = `&concat_cliente='*${nome}*'&bloqueada=false&ativa=true`;

            setLloading(true);
            const response = await axios.get(
                `${API.clientes}?email=${email}${where}`,
                {
                    headers: {
                        'x-access-token': token,
                    },
                },
            );

            //função que troca null por "" para evitar erro em tela para objeto nulo .
            var k = '';
            var v = ';';
            for (const obj of response.data.data) {
                if (typeof obj !== 'object') continue;
                for (k in obj) {
                    if (!obj.hasOwnProperty(k)) continue;
                    v = obj[k];
                    if (v === null || v === undefined) {
                        obj[k] = '';
                    }
                }
            }

            setLloading(false);
            const data = response.data.data.map(item => {
                return {
                    value: item.id,
                    label: item.id + ' - ' + item.nome_concat.toUpperCase(),
                    dados: item,
                };
            });

            // console.log(data);
            return data;
        } catch (err) {
            //              toast.error("Não encontrado");
            setLloading(false);
        }
    };

    //DEBOUNCE CLIENTES
    const loadOptionsClientes = (inputValue, callback) =>
        loadClients(inputValue, callback);

    const debouncedLoadOptionsClientes = debounce(loadOptionsClientes, 3000, {
        leading: false,
    });


    useEffect(() => {
        async function handleCleanSup() {
            setIdSupervisor("");
        }

        if (autoNomeSupervisor.length === 0 && perfil !== 'vendedor') {
            handleCleanSup();
        }

    }, [autoNomeSupervisor]);



    useEffect(() => {
        async function handleCleanVendedor() {
            setValueAutoId("");
        }
        if (valueAutoNome.length === 0) {
            handleCleanVendedor();
        }
    }, [valueAutoNome]);

    useEffect(() => {
        async function handleReq() {
            try {
                const response = await axios.get(
                    `${API.vendedores}?email=${email}`,
                    {
                        headers: {
                            "x-access-token": token
                        }
                    }
                );

                const list = response.data.data;
                setAuto(list);
            } catch (error) {
                if (error.response && error.response.status === 402) {
                    //token expirado
                    toast.error("Sua sessão expirou, favor efetuar login");
                    sessionStorage.clear();
                } else {
                    toast.error("Erro ao carregar ");
                }
            }
        }
        const getSupervisores = async () => {
            try {
                const response = await axios.get(`${API.usuarios}`, {
                    headers: {
                        'x-access-token': token,
                    },
                });

                const data = response.data.data;
                if (perfil !== 'vendedor' && perfil !== 'supervisor' && perfil !== 'assistente') {
                    setAutoListSupervisor(
                        data.filter(function (obj) {
                            return obj.USUARIO_PERFIL === 'supervisor';
                        }),
                    );
                }
                else if (perfil === 'supervisor'  ) {
                    setAutoListSupervisor(
                        data.filter(function (obj) {
                            return obj.USUARIO_ID == id_user;
                        }),
                    );
                }
                else {

                    const id_sup = data.filter(function (obj) {
                        return obj.USUARIO_ID == id_user;
                    })

                    if (id_sup[0] && id_sup[0].USUARIO_CONTA_SUPERVISOR_ID) {
                        setAutoListSupervisor(
                            data.filter(function (obj) {
                                return obj.USUARIO_ID == id_sup[0].USUARIO_CONTA_SUPERVISOR_ID;
                            }),
                        )
                    };

                }

                //filter perfil por supervisor
                //      setAutoListGerente(data);
            } catch (error) {
                toast.error('Erro ao carregar lista.');
            }
        };

        async function handleReqStatusPedido() {
            try {
                const response = await axios.get(
                    `${API.statuspedidos}`,
                    {
                        headers: {
                            "x-access-token": token
                        }
                    }
                );
                const status = response.data.data.map(item => {
                    return {
                        value: item.CODIGO,
                        label: item.DESCRICAO,
                    };
                });
                setStatus_options(status);


            } catch (error) {
                if (error.response && error.response.status === 402) {
                    //token expirado
                    toast.error("Sua sessão expirou, favor efetuar login");
                    sessionStorage.clear();
                } else {
                    toast.error("Erro ao carregar ");
                }
            }
        }
        handleReqStatusPedido();
        handleReq();
        getSupervisores();
    }, []);

    const Pesquisaitem = (data, e) => {
        e.preventDefault();
        setPanelOpen(false);
        handleSearch(data);
    };

    const handleSearch = async data => {
        try {
            setLloading(true);
            if (data.dataini === '' && data.datafim === '' && data.numero === '') {
                toast.error("Campo data Inicial e final são obrigatórios.");
                setLloading(false);
            } else {

                toast.success("Pesquisando");

                var where = "";
                where = where + `&perfil=${perfil}`;

                if (data.dataini !== '' && data.datafim != '') {
                    where = where + `&tipo=${data.tipo}&dt_inicial=${data.dataini}&dt_final=${data.datafim}`;
                }
                if (statusPedidos.length > 0) {
                    var stats = [];

                    statusPedidos.map(item => stats.push(item.value));
                    //    console.log(stats)
                    where = where + `&status=${stats}`;
                }


                if (idCliente > 0) {
                    where = where + `&cliente=${idCliente}`;
                }


                if (data.numero !== '') {
                    where = where + `&numero_pedido=${data.numero}`;
                }


                if (data.vendedorPadraoId > 0) {
                    where = where + `&vendedor=${data.vendedorPadraoId}`;

                }
                if (idSupervisor > 0) {

                    where = where + `&supervisor=${idSupervisor}`;
                }

                try {
                    const response = await axios.get(`${API.pedidos}/pedidossql/?email=${email}${where}`, {
                        headers: {
                            "x-access-token": token
                        }
                    });

                    const listPedidos = response.data.data;
console.log(listPedidos)
                    const isarray = Array.isArray(listPedidos);

                    var k = "";
                    var v = ";"
                    for (const obj of listPedidos) {
                        if (typeof obj !== 'object') continue;
                        for (k in obj) {
                            if (!obj.hasOwnProperty(k)) continue;
                            v = obj[k];
                            if (v === null || v === undefined) {
                                obj[k] = '';
                            }
                        }
                    }

                    setDadosPedidos(listPedidos);
                    const total = listPedidos ? listPedidos.reduce((total, listPedidos) => total + listPedidos.PEDIDO_VALOR, 0) : 0;
                    settotalPedidos(total);
                    setLloading(false);
                } catch (err) {
                    setLloading(false);
                    if (err.response.status === 402) {
                        //token expirado
                        toast.error("Sua sessão expirou, favor efetuar login");
                        sessionStorage.clear();
                    } else {
                        toast.error("Dados não localizados");
                    }
                }


            }
        } catch (error) {
            setLloading(false);

            toast.error("Não localizado, verifique os campos de pesquisa.");
        }
    };

    const rowsList = dadosPedidos
        ? dadosPedidos.map(item => {
            const {
                APELIDO,
                CLIENTE_APELIDO,
                CLIENTE_RAZAO_SOCIAL,
                NUMERO_SISTEMA,
                STATUS_DESCRICAO,
                PEDIDO_PRAZO_PAGAMENTO,
                PEDIDO_EMISSAO,
                PEDIDO_PREVISAO,
                PEDIDO_REFERENCIA,
                PEDIDO_VALOR,
                PEDIDO_OBSERVACAO,
                GERENTE_NOME,
                NOTA_FISCAL_NUMERO,
                NOTA_FISCAL_EMISSAO,
                NOTA_FISCAL_VALOR,
                ROMANEIO_NUMERO,
                TRANSPORTADORA_NOME,
                VENDEDOR_APELIDO
            } = item;
            const array = [];

            const row = createData(
                NUMERO_SISTEMA ? NUMERO_SISTEMA : "",
                STATUS_DESCRICAO ? STATUS_DESCRICAO : "",
                CLIENTE_RAZAO_SOCIAL ? CLIENTE_RAZAO_SOCIAL : "",
                moment(PEDIDO_EMISSAO.slice(0, 10)).format("DD/MM/YYYY"),
                moment(PEDIDO_PREVISAO.slice(0, 10)).format("DD/MM/YYYY"),
                PEDIDO_REFERENCIA,
                PEDIDO_VALOR.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
                <ModalPedidosSQL data={item} />,
                <Pdf num={NUMERO_SISTEMA} />,
                <SendEmail num={NUMERO_SISTEMA} />
            );

            return row;
        })
        : [{ error: "Não encontrado" }];

    return (
        <>
            <Pesquisa>
                <div>
                    <ExpansionPanel expanded={PanelOpen}>
                        <ExpansionPanelSummary
                            expanded={PanelOpen}
                            onClick={() => {
                                setPanelOpen(!PanelOpen);
                            }}
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography component={"span"} className={classes.heading}>
                                Painel de pesquisa
                            </Typography>
                        </ExpansionPanelSummary>

                        <ExpansionPanelDetails>
                            <Typography component={"div"} style={{minWidth:'100%'}}>
                                <Form>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12} lg={2}>
                                            <div className="input">
                                                <label>Número</label>
                                                <input type="number" name="numero" ref={register} />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={3}>
                                            <div className="input">
                                                <label>Vendedor</label>
                                                <input
                                                    name="vendedorPadraoId"
                                                    type="hidden"
                                                    ref={register}
                                                    defaultValue={valueAutoId}
                                                />
                                                <input
                                                    name="vendedorPadraoNome"
                                                    type="hidden"
                                                    ref={register}
                                                />
                                                <Autocomplete
                                                    items={auto}
                                                    wrapperStyle={{
                                                        position: "relative",
                                                        zIndex: "88885",
                                                        display: "inline-block"
                                                    }}
                                                    shouldItemRender={(item, value) =>
                                                        item.NOME.toLowerCase().indexOf(
                                                            value.toLowerCase()
                                                        ) > -1
                                                    }
                                                    getItemValue={item => {
                                                        setValueAutoId(item.NUMCAD1);
                                                        return item.NOME;
                                                    }}
                                                    renderItem={(item, isHighlighted) => (
                                                        <div
                                                            key={item.NUMCAD1}
                                                            inputVariant="outlined"
                                                            style={{
                                                                background: isHighlighted
                                                                    ? "lightgray"
                                                                    : "white",
                                                                width: "100%"
                                                            }}
                                                        >
                                                            <span
                                                                key={item.NUMCAD1}
                                                                style={{
                                                                    fontWeight: isHighlighted ? 700 : 400
                                                                }}
                                                            >
                                                                {item.NOME}
                                                            </span>
                                                        </div>
                                                    )}
                                                    value={valueAutoNome}
                                                    onChange={e => setValueAutoNome(e.target.value)}
                                                    onSelect={val => setValueAutoNome(val)}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={3}>
                                            <div className="input" id="sup">
                                                <label>Supervisor</label>
                                                <input
                                                    name="supervisorId"
                                                    type="hidden"
                                                    ref={register}
                                                    defaultValue={idSupervisor}
                                                />

                                                <Autocomplete
                                                    renderInput={props => (
                                                        <input {...props} autoComplete={false} />
                                                    )}
                                                    items={autoListSupervisor}
                                                    shouldItemRender={(item, value) =>
                                                        item.USUARIO_NOME.toLowerCase().indexOf(
                                                            value.toLowerCase(),
                                                        ) > -1
                                                    }
                                                    getItemValue={item => {
                                                        setIdSupervisor(item.USUARIO_CONTA_ID_ERP);
                                                        return item.USUARIO_NOME;
                                                    }}
                                                    menuStyle={{
                                                        borderRadius: '3px',
                                                        boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                                                        background: 'rgba(255, 255, 255, 0.9)',
                                                        padding: '2px 0',
                                                        fontSize: '90%',
                                                        position: 'fixed',
                                                        overflow: 'auto',
                                                        maxHeight: '50%',
                                                        zIndex: '400',
                                                    }}
                                                    renderItem={(item, isHighlighted) => (
                                                        <div
                                                            key={item.USUARIO_CONTA_ID_ERP}
                                                            style={{
                                                                background: isHighlighted
                                                                    ? 'lightgray'
                                                                    : 'white',
                                                                width: '100%',
                                                            }}
                                                        >
                                                            {item.USUARIO_NOME}
                                                        </div>
                                                    )}
                                                    value={autoNomeSupervisor}
                                                    onChange={e => setAutoNomeSupervisor(e.target.value)}
                                                    onSelect={val => setAutoNomeSupervisor(val)}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={4}>
                                            <div className="input">
                                                <label>Cliente</label>
                                                <Async
                                                    //onChange={e => (itemRequest.prazo_pagamentor = e.target.value)}
                                                    loadOptions={debouncedLoadOptionsClientes}
                                                    cacheOptions
                                                    isClearable={true}
                                                    noOptionsMessage={() => 'Nenhuma opção encontrada'}
                                                    placeholder="Cliente"
                                                    menuPortalTarget={document.body}
                                                    value={{
                                                        label: nomeCliente ? nomeCliente : '',
                                                        value: idCliente ? idCliente : '',
                                                    }}
                                                    onChange={value => {
                                                        const valor = value === null ? '' : value.value;

                                                        if (valor > 1) {
                                                            setIdCliente(valor);
                                                            setNomeCliente(value.label);
                                                        } else {
                                                            setIdCliente('');
                                                            setNomeCliente('');
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </Grid>


                                        <Grid item xs={12} sm={12} lg={4}>
                                            <div className="input">
                                                <label>Tipo</label>
                                                <select name="tipo" ref={register}>
                                                    <option value={"emissao"}>EMISSÃO</option>
                                                    <option value={"previsao"}>PREVISÃO</option>
                                                </select>
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} sm={12} lg={4}>
                                            <div className="input">
                                                <label>Data Inicial</label>
                                                <input type="date" name="dataini" ref={register} />
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} sm={12} lg={4}>
                                            <div className="input">
                                                <label>Data Final</label>
                                                <input type="date" name="datafim" ref={register} />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={12}>
                                            <div className="input">
                                                <label>Status</label>
                                                <Select
                                                    name="status"
                                                    placeholder="Status"
                                                    menuPortalTarget={document.body}

                                                    styles={{
                                                        menuPortal: base => ({
                                                            ...base,
                                                            zIndex: 22194,
                                                        }),

                                                        container: base => ({
                                                            ...base,
                                                            minWidth: '8rem',
                                                        }),
                                                    }}


                                                    defaultValue={statusPedidos ? statusPedidos : []}
                                                    options={status_options}
                                                    onChange={value => {
                                                        setStatusPedidos(value);
                                                    }}
                                                    isMulti
                                                />
                                            </div>
                                        </Grid>

                                    </Grid>
                                    <ButtonStyled
                                        variant="contained"
                                        color="primary"
                                        onClick={e => Pesquisaitem(getValues(), e)}
                                    >
                                        Pesquisar
                                    </ButtonStyled>
                                </Form>
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </div>
            </Pesquisa>
            <DataTable
                rows={rowsList}
                sort={true}
                rowHead={rowHead}
                title={"Pedidos"}
                titleNoData={"Pesquise os pedidos"}
                load={loading}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Paper elevation={9} square >

                    <span><b>Total: </b></span> {totalPedidos ? totalPedidos.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL"
                    }) : '0,00'}
                </Paper>
            </div>
        </>
    );
}

export default connect()(Pedidos);
