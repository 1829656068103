import React from "react";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import axios from "axios";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Button } from "./styles";
import {API} from "../../../config/api"

export default function Pdf({ num }) {
  const token = sessionStorage.getItem("token");
  const email = sessionStorage.getItem("email");
  
  const handlePdf = async (e) => {
  if(num){
    try {
      e.preventDefault();

      const req = await axios.get(
       `${API.produtos_especbyurl}/${num}`,

        {
          headers: {
            "x-access-token": token
          }
        }
      );
  
    req.data.data[0].URL_ESPEC!==null? window.open(req.data.data[0].URL_ESPEC):toast.error('Especificação do produto não encontrada.')
    } catch (err) {
      toast.error("Não foi possível gerar seu PDF");
    }
  }
  };
  
  return (
    <Button type="button" onClick={(e) => handlePdf(e)}>
      <PictureAsPdfIcon />
    </Button>
  );
}

Pdf.propTypes = {
  num: PropTypes.number.isRequired
};
