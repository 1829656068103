import React from "react";

import Screen from "../../components/Screen";

import { Container } from "./styleLogin";

export default function Login() {
  return (
    <Container>
      <Screen />
    </Container>
  );
}
