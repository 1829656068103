import React from "react";
import { ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import { Router, Switch, Redirect } from "react-router-dom";

import Route from "./Route";

import "./config/ReactotronConfig";
import GlobalStyle from "./styles/global";

import history from "./services/history";
import { store, persistor } from "./store";

// core components
import Admin from "layouts/Admin.js";
import Login from "layouts/login/Login.js";
import "assets/css/material-dashboard-react.css?v=1.8.0";

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Router history={history}>
        <Switch>
          <Route path="/" exact component={Login} />
          <Route path="/admin" component={Admin} isPrivate />
          <Redirect from="/" to="/admin/dashboard" />
        </Switch>
        <GlobalStyle />
        <ToastContainer autoClose={3000} />
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
