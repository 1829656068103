import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Delete from "../../components/Regras/DeleteListaPrecos";
import DataTable from "components/Table/Table.js";
import ModalListaPrecos from "../../components/Regras/ModalListaPrecos";
import ModalCreateListasPrecos from "../../components/Regras/ModalCreateListaPrecos";
import {API} from "../../config/api"


const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: "15px"
  }
}));

function createData(
  LISTA_PRECOS_NOME,
  LISTA_PRECOS_DESCRICAO,
  edit,
  del
) {
  return {
    LISTA_PRECOS_NOME,
    LISTA_PRECOS_DESCRICAO,    
    edit,
    del
  };
}

const rowHead = [
  {
    title: "Nome",
    field: "LISTA_PRECOS_NOME"
  },
  {
    title: "Descrição",
    field: "LISTA_PRECOS_DESCRICAO"
  },
  {
    field: "edit"
  },
  {
    field: "del"
  }
];

export default function Precos() {
  const classes = useStyles();
  const token = sessionStorage.getItem('token');
  const email = sessionStorage.getItem('email');
  const [listRegras, setlistRegras] = useState([]);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    const req = async () => {
      try {
        const response = await axios.get(
          `${API.listaprecos}?email=${email}`,
          {
            headers: {
              "x-access-token": token
            }
          }
        );

        const lista = response.data.data;
        console.log(lista);
        setlistRegras(lista);
        setLoad(false);
      } catch (err) {
        toast.error("Você não tem permissão para visualizar essa tabela");
      }
    };

    req();
  }, []);

  const rowsList = listRegras
    ? listRegras.map(item => {
        const {
          LISTA_PRECOS_ID,
          LISTA_PRECOS_NOME,
          LISTA_PRECOS_DESCRICAO
        } = item;

        const row = createData(
          LISTA_PRECOS_NOME,
          LISTA_PRECOS_DESCRICAO,
          <ModalListaPrecos data={item} />,
          <Delete id={LISTA_PRECOS_ID} />
        );

        return row;
      })
    : [{ error: "Não encontrado" }];

  return (
    <>
      <Paper className={classes.paper}>
        <ModalCreateListasPrecos />
        <DataTable
          load={load}
          rows={rowsList}
          rowHead={rowHead}
          title={"Lista de preços"}
        />
      </Paper>
    </>
  );
}
