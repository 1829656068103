import styled from "styled-components";

export const Container = styled.div`
  text-align: center;
  width: auto;
  margin: 10px 15px 0;
  display: block;
  padding: 5px 10px;
  position: relative;
  transition: all 300ms linear;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.2em;
  border-radius: 3px;
  background-color: transparent;

  button {
    width: 100%;
  }

  a {
    color: #000 !important;
  }
`;
