const server = `http://diagonal.salesbreath.com.br:3002/api/v1`;
//const server =   `http://localhost:3002/api/v1`;

export const API = {
    clientes: `${server}/clientes`,
    clientesByname: `${server}/clientes/contasByName`,
    vendedores: `${server}/clientes/vendedores`,
    totalfaturado: `${server}/dashboard/totalfaturado`,
    qtdvendasmes: `${server}/dashboard/qtdvendasmes`,
    vlvendasmes: `${server}/dashboard/vlvendasmes`,
    empresa: `${server}/empresa`,
    organizacao: `${server}/organizacao`,
    pedidos: `${server}/pedidos`,
    listaprecos: `${server}/regras/lista_precos`,
    transportadorasByClienteId: `${server}/clientes/transportadorasByClienteId`,
    carrinho: `${server}/carrinho`,
    carrinhoremoveitem: `${server}/carrinho/removeItem`,
    carrinho_removecart: `${server}/carrinho/removecart`,
    adicionaaocarrinho: `${server}/carrinho/AdicionaAoCarrinho`,
    numeropedido_erp: `${server}/bancoERP/numeropedido`,
    sendmailPedidos: `${server}/utilities/sendmailPedidos`,
    transportadoras: `${server}/clientes/transportadoras`,
    produtos: `${server}/Produtos`,
    regras: `${server}/regras`,
    regrasatribuidas: `${server}/regras/regras_atribuidas`,
    usuarios: `${server}/usuarios`,
    usuarios_delete: `${server}/usuarios/delete`,
    empresa_erp: `${server}/empresa/erp`,
    impressos_pedidos: `${server}/impressos/pedidos`,
    produtos_especbyurl: `${server}/produtos/especByUrl`,
    produtos_espec: `${server}/impressos/espec`,
    refreshToken: `${server}/usuarios/refreshToken`,
    consultasaldo: `${server}/Produtos/consultasaldo`,
    carteiras: `${server}/utilities/carteiras`,
    estados: `${server}/utilities/estados`,
    classes: `${server}/utilities/classes`,
    relatorios: `${server}/relatorios`,
    departamentos: `${server}/utilities/departamentos`,
    marcas: `${server}/utilities/marcas`,
    imagens_produtos: `${server}/utilities/imagens`,
    prazomedio: `${server}/utilities/prazomedio`,
    infocomerciais: `${server}/clientes/infocomerciais`,
    galeria: `http://localhost:3002`,
    imagens_colecao: `${server}/imagensmpc`,
    statuspedidos: `${server}/utilities/statuspedidos`,
    usuarios_erp: `${server}/usuarios/usuarios_erp`,
    getprzpag: `${server}/utilities/prazopagamento`,
    clienteSQL: `${server}/clientes/clienteSQL`,
    classesReport: `${server}/utilities/reportClasses`,
    produtos_NameOrCod: `${server}/Produtos/byNameAndCod`



}
