/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useSelector, connect, useDispatch } from 'react-redux'
import DataTable from 'components/Table/Table.js'
import Carrinho from 'components/CarrinhoModal'
import ItemProdutos from 'components/Pedidos/ModalItemProdutos'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { withStyles } from '@material-ui/core/styles'

import IconRequestPass from '@material-ui/icons/CheckCircle'
import IconRequestfailure from '@material-ui/icons/Cancel'

import { ToastContainer, toast } from 'react-toastify'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { Card, CardHeader, CardContent } from '@material-ui/core'

import { ReactMultiEmail } from 'react-multi-email'
// import Pdf from "../../components/Produtos/PDF";
// import PdfModal from "../../components/Produtos/PDFModal";
import Badge from '@material-ui/core/Badge'

import PropTypes from 'prop-types'

import Autocomplete from 'react-autocomplete'
import AutoCompleteCustom from 'components/AutoCompleteCustom'
//import TabelaPedidos7days from "components/TabelaPedidos7days";
import Typography from '@material-ui/core/Typography'
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import ExpansionPanel from "@material-ui/core/ExpansionPanel";
// import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import LinearProgress from '@material-ui/core/LinearProgress'

import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import { API } from '../../config/api'
import history from '../../services/history'
import Async from 'react-select/async'
import debounce from 'debounce-promise'

import {
  Pesquisa,
  Form,
  ButtonStyled,
  ButtonRequest,
  Input,
  Select,
  FormAuto,
  PaperStyle,
} from './stylesFinaliza'

import axios from 'axios'
import moment from 'moment'
import { CenterFocusStrong } from '@material-ui/icons'

// Função para criar OBJ dos Pedidos
function createRequest(
  view,
  idReq,
  busines,
  dateProg,
  freight,
  total,
  paymentTerm,

  idOperator,
  idCarriers,
) {
  return {
    view,

    idReq,
    busines,
    dateProg,
    freight,
    total,
    paymentTerm,
    idOperator,
    idCarriers,
  }
}

const headRequest = [
  {
    title: '',
    field: 'view',
    headerStyle: {
      width: 10,
      maxWidth: 10,
      padding: 1,
    },
    cellStyle: {
      fontSize: '10px',
      whiteSpace: 'nowrap',
      padding: '1px',
    },
  },

  {
    title: 'Referência',
    field: 'idReq',
  },
  {
    title: 'Empresa',
    field: 'busines',
  },
  {
    title: 'Programação',
    field: 'dateProg',
  },
  {
    title: 'Frete',
    field: 'freight',
  },

  {
    title: 'Total',
    field: 'total',
  },
  {
    title: 'Prazo de Pagamento',
    field: 'paymentTerm',
  },
]

// Funções para criar o OBJ FinishRequest
function createDataFinishRequest(
  mailsent,
  icon,
  items,
  numeroSistema,
  numeroReferencia,
  prz_pag,
  programacao,
  cliente,
  status,
) {
  return {
    mailsent,
    icon,
    items,
    numeroSistema,
    numeroReferencia,
    prz_pag,
    programacao,
    cliente,
    status,
  }
}

const rowHeadRequest = [
  {
    title: '',
    field: 'mailsent',
    cellStyle: {
      fontSize: '12px',
    },
  },

  {
    title: 'Status',
    field: 'icon',
    cellStyle: {
      fontSize: '12px',
    },
  },
  {
    title: '',
    field: 'items',
    cellStyle: {
      fontSize: '12px',
    },
  },
  {
    title: 'N.Pedido',
    field: 'numeroSistema',
    cellStyle: {
      fontSize: '12px',
    },
  },
  {
    title: 'Referência',
    field: 'numeroReferencia',
    cellStyle: {
      fontSize: '12px',
    },
  },
  {
    title: 'Prazo Pagamento',
    field: 'prz_pag',
    cellStyle: {
      fontSize: '12px',
    },
  },
  {
    title: 'Programação',
    field: 'programacao',
    cellStyle: {
      fontSize: '12px',
    },
  },
  {
    title: 'Cliente',
    field: 'cliente',
    cellStyle: {
      fontSize: '12px',
    },
  },
  {
    title: 'Status',
    field: 'status',
    cellStyle: {
      fontSize: '12px',
    },
  },
]

function FinalizaPedido({ itemCart, Transp, Clients }) {
  const [isConfirmReq, setisConfirmReq] = useState(true)
  const [isFinishRequest, setIsFinishRequest] = useState(false)
  const [isViewRequest, setIsViewRequest] = useState(false)

  const [lista, setLista] = useState([])
  const [listCart, setListCart] = useState([itemCart])
  const [loading, setLloading] = useState(true)
  const [valueIp, setValue] = useState('')

  const [progress, SetProgress] = useState(false)
  const [itemSendRequest, setItemSendRequest] = useState([])

  const [autoCliente, setAutoCliente] = useState([])
  const [autoVendedor, setAutoVendedor] = useState([])
  const [autoTransp, setTransp] = useState(Transp)

  const [listRequest, setListRequest] = useState([])

  const [nomeCliente, setNomeCliente] = useState('')
  const [nomeClienteForm, setNomeClienteForm] = useState('')

  const [nomeTransp, setNomeTransp] = useState('')
  const [nomeTranspForm, setNomeTranspForm] = useState('')

  const [nomeTranspRedespacho, setNomeTranspRedespacho] = useState('')
  const [nomeTranspRedespachoForm, setNomeTranspRedespachoForm] = useState('')

  const [idCliente, setIdCliente] = useState('')
  const [idClienteForm, setIdClienteForm] = useState('')

  const [vendedorP, setVendedorP] = useState('')
  const [idVendedorP, setIdVendedorP] = useState('')
  const [vendedorNomeForm, setVendedorNomeForm] = useState('')

  const [idTransp, setIdTransp] = useState('')
  const [idTranspRedespacho, setIdTranspRedespacho] = useState('')
  const [obs, setObs] = useState('')

  const [emails, setEmail] = useState([])

  const token = sessionStorage.getItem('token')
  const email = sessionStorage.getItem('email')
  const userId = sessionStorage.getItem('id')
  const userIdErp = sessionStorage.getItem('id_erp')
  const clientes = sessionStorage.getItem('clientes')
  const [validacao, setValidacao] = useState([])
  const [indexTable, setIndexTable] = useState()
  const [prz, setPrz] = useState('')
  const [nomeClienteEntrega, setNomeClienteEntrega] = useState('')
  const [showTriangClient, setshowTriangClient] = useState(false)
  const [idClienteEntrega, setIdClienteEntrega] = useState('')
  const [cepCliente, setcepCliente] = useState('')
  const [cnpjCliente, setcnpjCliente] = useState('')

  ////console.lo(clientes);

  const isRequest = listCart.length === 0

  const useStyles = makeStyles((theme) => ({
    paper: {
      backgroundColor: theme.palette.background.paper,
      paddingTop: '5px',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    paper2: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      minWidth: '50%',
      minHeight: '50%',
    },
    progress: {
      marginTop: 200,

      textAlign: 'center',
      alignItems: 'center',
      width: '100%',
      '& > * + *': {},
    },
    root: {
      display: 'flex',
      minWidth: 320,
      maxWidth: 500,
      flexDirection: 'column', //change to row for horizontal layout
      '& .MuiCardHeader-root': {
        backgroundColor: 'yellow',
      },
      '& .MuiCardHeader-root': {
        backgroundColor: 'yellow',
      },
      '& .MuiCardHeader-title': {
        //could also be placed inside header class
        backgroundColor: '#FCFCFC',
      },
      '& .MuiCardHeader-subheader	': {
        backgroundImage: 'linear-gradient(to bottom right, #090977, #00d4ff);',
      },
      '& .MuiCardContent-root': {
        backgroundImage: 'linear-gradient(to bottom right, #00d4ff, #00ff1d);',
      },
    },
    header: {
      fontSize: '1.15rem',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontWeight: 500,
      textAlign: 'center',
      color: 'black',
      lineHeight: 1.4,
    },
    content: {
      display: 'flex',
      minHeight: '100%',
      flexWrap: 'wrap',
    },
    contentItem: {
      flex: 'calc(50% - 4px)',
      '@media (max-width: 500px)': {},
    },
    textContent: {
      fontSize: 18,
      textAlign: 'center',
      border: '1px solid black',
    },
    footer: {
      fontSize: 14,
      backgroundImage: 'linear-gradient(to bottom right, #8c9d9b, #bdcdbf);',
    },
    card: {
      maxWidth: '100%',
      minHeight: '100%',
      maxHeight: '100%',
      boxShadow: '0 5px 8px 0 rgba(0, 0, 0, 0.3)',
      backgroundColor: '#fafafa',
    },
  }))

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.text.secondary,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 10,
    },
  }))(TableCell)
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow)

  const StyledTableCellPrimary = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.text.primary,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 16,
    },
  }))(TableCell)

  const StyledBadge = withStyles((theme) => ({
    badge: {
      right: 4,
      top: 5,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }))(Badge)

  const classes = useStyles()
  const perfil = sessionStorage.getItem('perfil')
  useEffect(() => {
    setIdVendedorP(vendedorP.length === 0 ? '' : idVendedorP)
  }, [vendedorP])

  useEffect(() => {
    const loadSales = async () => {
      try {
        const response = await axios.get(`${API.vendedores}?email=${email}`, {
          headers: {
            'x-access-token': token,
          },
        })

        setAutoVendedor(response.data.data)
      } catch (err) {
        if (err.response && err.response.status === 402) {
          //token expirado
          toast.error('Sua sessão expirou, favor efetuar o login')
          sessionStorage.clear()
        } else {
          sessionStorage.clear()

          toast.error('Erro ao carregar lista de vendedores')
        }
      }
    }

    // loadCarriers();
    setAutoCliente(clientes ? JSON.parse(clientes) : [])

    loadSales()
    confirmRequest()
    //   //console.lo(">>>> TOKEN:", token);
    //  //console.lo(">>>> userIdErp:", userIdErp);
  }, [])

  const loadClients = async (inputValue, callback) => {
    try {
        inputValue = encodeURIComponent(inputValue);

      var where = `&concat_cliente='*${inputValue}*'&bloqueada=false&ativa=true`

      const response = await axios.get(
        `${API.clientes}?email=${email}${where}`,
        {
          headers: {
            'x-access-token': token,
          },
        },
      )

      //função que troca null por "" para evitar erro em tela para objeto nulo .
      var k = ''
      var v = ';'
      for (const obj of response.data.data) {
        if (typeof obj !== 'object') continue
        for (k in obj) {
          if (!obj.hasOwnProperty(k)) continue
          v = obj[k]
          if (v === null || v === undefined) {
            obj[k] = ''
          }
        }
      }

      const data = response.data.data.map((item) => {
        return {
          value: item.id,
          label: (
            <span
              dangerouslySetInnerHTML={{
                __html:
                  '<strong>Id:</strong> ' +
                  item.id +
                  '<br> <strong>Nome/Apelido:</strong>' +
                  item.nome_concat +
                  '<br><strong>CNPJ/CPF:</strong>' +
                  item.cnpj +
                  item.cpf +
                  '<br><strong>Cidade:</strong>' +
                  item.enderecoCidade +
                  '/' +
                  item.enderecoEstado +
                  '<hr>',
              }}
            />
          ),
          labelshow: item.nome_concat + ' ' + item.cnpj,
          dados: item,
        }
      })
      //console.lo(data);
      return data
    } catch (err) {}
  }
  const loadCarriers = async (inputValue, callback) => {
    try {
      var where = `&concat_transp='${inputValue}'`
      const responseTransp = await axios.get(
        `${API.transportadoras}?email=${email}${where}`,
        {
          headers: {
            'x-access-token': token,
          },
        },
      )

      const data = responseTransp.data.data.map((item) => {
        return {
          value: item.NUMCAD1,
          label: (
            <span
              dangerouslySetInnerHTML={{
                __html:
                  '<strong>Id:</strong> ' +
                  item.NUMCAD1 +
                  '<br> <strong>Nome/Apelido:</strong>' +
                  item.NOME +
                  '/' +
                  item.APELIDO +
                  '<br><strong>CNPJ:</strong>' +
                  item.CGC +
                  '<hr>',
              }}
            />
          ),
          labelshow: item.NOME + ' ' + item.CGC,
          dados: item,
        }
      })
      //console.lo(data);
      return data
    } catch (err) {
      if (err.response && err.response.status === 402) {
        //token expirado
        toast.error('Sua sessão expirou, favor efetuar o login')
        sessionStorage.clear()
      } else {
        toast.error('Erro ao carregar lista de transportadoras')
      }
    }
  }

  //DEBOUNCE TRANSPORTADORA
  const loadOptionsTransp = (inputValue, callback) =>
    loadCarriers(inputValue, callback)

  const debouncedLoadOptionsTransp = debounce(loadOptionsTransp, 3, {
    leading: false,
  })
  //DEBOUNCE CLIENTES
  const loadOptionsClientes = (inputValue, callback) =>
    loadClients(inputValue, callback)

  const debouncedLoadOptionsClientes = debounce(loadOptionsClientes, 3, {
    leading: false,
  })

  useEffect(() => {
    const validaCampos = async () => {
      try {
        //  setFirstload(false);

        setValidacao(
          validacao.map((item) =>
            item.index === indexTable ? { ...item, validacao: 0 } : item,
          ),
        )

        //itemSendRequest.map((itemRequest, idx) => {
        itemSendRequest.forEach(async (itemRequest, idx) => {
          const rowIndex = idx
          if (rowIndex === indexTable) {
            //console.log(rowIndex + indexTable);
            console.log(prz)
            //   console.log(itemRequest.);
            if (prz == '') {
              setValidacao(
                validacao.map((item) =>
                  item.index === indexTable ? { ...item, validacao: 0 } : item,
                ),
              )
            } else {
              setValidacao(
                validacao.map((item) =>
                  item.index === indexTable ? { ...item, validacao: 1 } : item,
                ),
              )
            }
          }
        })
      } catch (err) {
        if (err.response && err.response.status === 402) {
          //token expirado
          toast.error('Sua sessão expirou, favor efetuar o login')
        } else {
          //   toast.error('Erro ao obter prazo médio.');
        }
      }
    }

    validaCampos()
  }, [indexTable, prz])

  async function confirmRequest() {
    setisConfirmReq(true)

    var prazo_medio = ''

    const response = await axios.get(`${API.carrinho}?email=${email}`, {
      headers: {
        'x-access-token': token,
      },
    })

    if (validacao.length == 0) {
      console.log('eeeee')
      setValidacao(
        response.data.data.map((item, index) => {
          return { index, validacao: 0 }
        }),
      )
    }
    const itemRequestSend = []
    const idNumberRequest = []
    for (let index = 0; index < response.data.data.length; index++) {
      const itemRequest = {
        email,
        numero_pedido: 0,
        cliente_id: idCliente,
        clienteNome: nomeClienteForm,
        vendedor_id: idVendedorP,
        vendedor_nome: vendedorP,
        operador_id: userIdErp,
        prazo_pagamento: '',
        prazo_pagamento_media: '',
        valor_parcelas: '',
        referencia: '',
        forma_pagamento: '',
        emissao: moment().format('YYYY/MM/DD'),
        empresa_id: 0,
        lista_preco: '1',
        tipo_frete: 'EMITENTE',
        observacoes: obs,
        emails_adicionais: email,
        dt_previsao_entrega: '',
        transportadora: 0,
        redespacho: 0,
        status: '$digitado',
        items: [...response.data.data[index]],
      }

      //validacao[index].validacao = 0;

      const listProducts = []
      let companyName = ''
      let companyId = 0
      let dateProgram = ''
      let totalPedido = 0
      let data_prog = ''

      // Coleta os itens do pedido (Produtos)
      for (let idx = 0; idx < response.data.data[index].length; idx++) {
        const i = response.data.data[index][idx]
        const item = {
          ID: i.ID,
          ITEM_ID: i.ITEM_ID,
          ITEM_NOME: i.ITEM_NOME,
          EMPRESA_ID: i.EMPRESA_ID,
          EMPRESA_APELIDO: i.EMPRESA_APELIDO,
          DATA_PROGRAMACAO: i.DATA_PROGRAMACAO,
          QUANTIDADE: i.QUANTIDADE,
          ITEM_GRADE: i.ITEM_GRADE,
          ITEM_UNIDADE: i.ITEM_UNIDADE,
          LISTA_PRECO: 'A90',
          VALOR_UNITARIO: i.VALOR_UNITARIO,
          VALOR_UNITARIO_PADRAO: i.VALOR_UNITARIO_PADRAO,
          VALOR_TOTAL: i.VALOR_TOTAL,
          TIPO_VENDA: i.TIPO_VENDA,
          PROGRAMACAO_NUMERO: i.PROGRAMACAO_NUMERO,
          PROGRAMACAO_ITEM_ID: i.PROGRAMACAO_ITEM_ID,
          CARRINHO_USUARIO_ID: i.CARRINHO_USUARIO_ID,
          CARRINHO_CONTA_ID: i.CARRINHO_CONTA_ID,
        }
        listProducts.push(item)
        totalPedido = totalPedido + i.QUANTIDADE * i.VALOR_UNITARIO
        if (companyName === '') {
          companyName = i.EMPRESA_APELIDO
          dateProgram = i.TIPO_VENDA.includes('P.E.')
            ? i.TIPO_VENDA
            : moment(i.DATA_PROGRAMACAO).format('DD/MM/YYYY')
          companyId = i.EMPRESA_ID
          data_prog = i.DATA_PROGRAMACAO
        }
      }

      //itemRequest.numero_pedido = respedidos.data.data.numero;
      itemRequest.empresa_id = companyId
      itemRequest.items = listProducts
      itemRequest.dt_previsao_entrega = data_prog
      const row = createRequest(
        <ItemProdutos itemCart={listProducts} />,

        <Input
          type="text"
          name="referenc"
          onChange={(e) => (itemRequest.referencia = e.target.value)}
          style={{
            minWidth: '6rem',
            maxWidth: '6rem',
            height: 'calc(1.5em + 0.35rem + 2px)',
            fontSize: '12px',
          }}
        />,
        companyName,
        dateProgram,
        <Select
          id="tipo_frete"
          name="tipo_frete"
          style={{
            minWidth: '9rem',
            height: 'calc(1.5em + 0.35rem + 2px)',
            fontSize: '12px',
          }}
          onChange={(e) => (itemRequest.tipo_frete = e.target.value)}
        >
          <option>EMITENTE</option>
          <option>DESTINATARIO</option>
        </Select>,
        totalPedido.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),

        <Input
          type="text"
          id="prazoPaga"
          name="prazoPaga"
          style={{
            minWidth: '15rem',
            maxWidth: '15rem',
            height: 'calc(1.5em + 0.35rem + 2px)',
            fontSize: '12px',
          }}
          onBlur={(e) => {
            setIndexTable(index)
          }}
          onChange={(e) => {
            itemRequest.prazo_pagamento = e.target.value
            setPrz(e.target.value)
          }}
        /> /*,
       <Input
          type="text"
          id="prazoPagaMedia"
          name="prazoPagaMedia"
          value={itemRequest.prazo_pagamento_media}
          style={{ minWidth: "15rem" }}
          onChange={e => {
            itemRequest.valor_parcelas = e.target.value;
          }}
        />,
        <Input
          type="text"
          id="valor_parcelas"
          name="valor_parcelas"
          value={itemRequest.valor_parcelas}
          style={{ minWidth: "15rem" }}
          onChange={e => {
            itemRequest.valor_parcelas = e.target.value;
          }}
        />*/,

        /* <Select
          style={{ minWidth: "8rem" }}
          onChange={e => (itemRequest.forma_pagamento = e.target.value)}
        >
          <option>Boleto</option>
          <option>Antecipado</option>
          <option>Boleto BX</option>
          <option>BX</option>
          <option>Bonificação</option>
        </Select>,*/
      )
      idNumberRequest.push(row)
      itemRequestSend.push(itemRequest)
    }
    setItemSendRequest(itemRequestSend)
    setListCart(idNumberRequest)
    setLloading(false)
  }

  async function orderViewCancel() {
    setIsViewRequest(false)
    setisConfirmReq(true)
  }
  async function orderView() {
    //setIsViewRequest();
    //if (isConfirmReq && !isFinishRequest && !isViewRequest && !progress) {
    //setIsViewRequest(true);
    var obs_concat = `${obs}`;

    if (showTriangClient) {
      obs_concat = obs_concat + "\r \n" +'Atenção: Operação triangular, cliente entrega:'+ nomeClienteEntrega;
    }


    for (let index = 0; index < itemSendRequest.length; index++) {

      const i = itemSendRequest[index]
      i.cliente_id = idCliente
      i.vendedor_id = idVendedorP
      i.observacoes = obs_concat

      i.transportadora = idTransp
      i.transportadora_nome = nomeTransp
      i.redespacho_nome = nomeTranspRedespacho
      i.redespacho = idTranspRedespacho
    }

    setIsViewRequest(true)
  }

  async function orderConfirmation() {
    if (idCliente.length === 0 || idVendedorP === 0) {
      toast.success('Favor preencher os campos obrigatórios')
    } else {
      setIsFinishRequest(true)
      setLloading(true)
      var responseContatos = null
      let emails = email ? email + ',' : ''
      try {
        responseContatos = await axios.get(
          `${API.clientes}/${idCliente}/contatos`,
          {
            headers: {
              'x-access-token': token,
            },
          },
        )

        if (responseContatos) {
          var responseContatosNew = responseContatos.data.data.sort(
            (a, b) => a.prioridade - b.prioridade,
          )
          var count_email = 0

          responseContatosNew.map((i) => {
            if (
              (i.tipo === 'EMAIL' || i.tipo === 'EMAIL_NFE') &&
              count_email == 0
            ) {
              emails = emails + `${i.descricao},`
              count_email++
            }
          })
          emails = emails.slice(0, -1)
          setEmail(emails.split(','))
        }
      } catch (error) {}

      const listRequestResponse = []

      for (let index = 0; index < itemSendRequest.length; index++) {

        const i = itemSendRequest[index]
        i.cliente_id = idCliente
        i.vendedor_id = idVendedorP
//        i.observacoes =obs
        i.transportadora = idTransp
        i.redespacho = idTranspRedespacho

        var erro = 'nao'
        var response = null

        const response2 = await axios.post(`${API.pedidos}`, i, {
          headers: {
            'x-access-token': token,
          },
        })
        if (
          response2.data &&
          response2.data.success &&
          parseInt(response2.data.status) === 200 &&
          response2.data.data.numeroSistema
        ) {
          //limpa carrinho(somente itens inseridos)
          for (let x = 0; x < i.items.length; x++) {
            try {
              await axios.delete(`${API.carrinhoremoveitem}/${i.items[x].ID}`, {
                headers: {
                  'x-access-token': token,
                },
              })
              //   toast.success("Item removido do carrinho com sucesso");

              //window.location.reload();
            } catch (err) {}
          }

          //atualizar carrinho que pedido foi gerado
          listRequestResponse.push(
            createDataFinishRequest(
              '',
              <IconRequestPass style={{ color: 'green' }} />,
              <ItemProdutos itemCart={i.items} />,
              response2.data.data.numeroSistema,
              i.referencia,
              i.prazo_pagamento,
              moment(i.dt_previsao_entrega).format('DD/MM/YYYY'),
              response2.data.data.conta.nome,
              'Enviado',
            ),
          )
        } else {
          toast.error(
            'Erro ao inserir. Os itens serão mantidos em seu carrinho',
          )

          listRequestResponse.push(
            createDataFinishRequest(
              '',
              <IconRequestfailure style={{ color: 'red' }} />,
              <ItemProdutos itemCart={i.items} />,
              '',
              i.referencia,
              i.prazo_pagamento,
              moment(i.dt_previsao_entrega).format('DD/MM/YYYY'),
              i.clienteNome,
              response2 && response2.data.data && response2.data.data.mensagem
                ? response2.data.data.mensagem
                : '',
            ),
          )
        }
      }

      ////console.lo(listRequestResponse);

      setListRequest(listRequestResponse)
      setLloading(false)
    }
  }

  async function cancelRequest() {
    setListCart([])
    setLista([])
    setisConfirmReq(false)
    history.push('/produtos')
  }

  async function sendUserNotification(e) {
    e.preventDefault()
    const emailSentTo = emails.join()
    var answer = window.confirm('Tem certeza que deseja enviar o email ?')
    if (answer) {
      const array_pedidos = []

      for (let index = 0; index < listRequest.length; index++) {
        const i = listRequest[index]
        if (i.numeroSistema > 0) {
          array_pedidos.push({ numero: i.numeroSistema })
        }
      }

      const data_sendEmail = {
        usuario: email,
        emails: emailSentTo,
        titulo: '',
        descricao: 'Pedido emitido pelo sistema SalesBreath',
        pedidos: array_pedidos,
      }
      // //console.lo("dados a enviar");
      // //console.lo(data_sendEmail);
      try {
        toast.success('Enviando email,aguarde.', { autoClose: 10000 })

        setLloading(true)

        const response_mail = await axios.post(
          `${API.sendmailPedidos}`,
          data_sendEmail,
          {
            headers: {
              'x-access-token': token,
            },
          },
        )
        setLloading(false)

        for (
          let idxmail = 0;
          idxmail < response_mail.data.data.data.length;
          idxmail++
        ) {
          listRequest[idxmail].mailsent = 'Email Enviado'
        }
        setListRequest(listRequest)
      } catch (error) {
        setLloading(false)
        toast.error(
          error.response && error.response.data.message
            ? error.response.data.message
            : 'Erro ao enviar email',
        )
      }
    } else {
      return null
    }
  }

  // Monta a tela conforme condiçoes das variaveis:
  //
  // isConfirmReq:
  // se for FALSE, renderiza tela de produtos para
  // inserção no carrinho, caso seja TRUE, carrinho
  // com produto para realizar processo de finalização de pedido.
  //
  // isFinishRequest:
  // se for FALSE, processo de finalização do pedido em curso.
  // se for TRUE, iniciou o processo de finalização, enviou os pedidos
  // para o back end, e esta exibindo o resulta em tela para o usuário.
  if (!isConfirmReq) {
    return null
  } else {
    if (progress) {
      return (
        <>
          <div className={classes.progress}>
            <span alignItems="center">
              Estamos carregando a lista de clientes, favor aguardar.
            </span>

            <LinearProgress />
          </div>
        </>
      )
    }

    if (isConfirmReq && !isFinishRequest && !isViewRequest && !progress) {
      // Inicia o processo de confirmação de pedido

      return (
        <>
          {' '}
          <FormAuto>
            <div className="lineForm">
              <Pesquisa>
                <div>
                  <Typography component={'div'}>
                    <FormAuto>
                      <Grid
                        container
                        spacing={1}
                        style={{
                          width: '100%',
                          minHeight: '100%',
                          maxHeight:'100%'
                        }}
                      >
                        <Grid item xs={12} lg={4} md={4}>
                          <Card className={classes.card}>
                            <CardHeader
                              className={classes.header}
                              component={Typography}
                              title={'DEFINIÇÕES DA VENDA'}
                            />
                            <CardContent>
                              <Grid
                                container
                                spacing={1}
                                style={{ width: '100%', background: 'white' }}
                              >
                                <Grid
                                  className="index"
                                  item
                                  xs={12}
                                  sm={12}
                                  lg={12}
                                >
                                  <input
                                    style={{
                                      minWidth: '1rem',
                                      width: '50px',
                                      verticalAlign: 'middle',
                                      height: 'calc(2em + 0.75rem + 2px)',
                                      fontSize: '12px',
                                      marginRight: '5px',
                                    }}
                                    type="checkbox"
                                    name="triangular"
                                    defaultChecked={false}
                                    onClick={() =>
                                      setshowTriangClient(!showTriangClient)
                                    }
                                  />
                                  <label>Operação Triangular? </label>
                                </Grid>
                                <Grid
                                  className="index2"
                                  item
                                  sm={12}
                                  lg={12}
                                  md={12}
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                    zIndex: '99',
                                    height: '30%',
                                  }}
                                >
                                  <div className="input">
                                    <label>Cliente</label>

                                    <Async
                                      //onChange={e => (itemRequest.prazo_pagamentor = e.target.value)}
                                      loadOptions={debouncedLoadOptionsClientes}
                                      cacheOptions
                                      menuPortalTarget={document.body}
                                      styles={{
                                        menuPortal: (base) => ({
                                          ...base,
                                          zIndex: 9999,
                                        }),
                                      }}
                                      isClearable={true}
                                      noOptionsMessage={() =>
                                        'Nenhuma opção encontrada'
                                      }
                                      placeholder="Clientes"
                                      value={{
                                        label: nomeCliente ? nomeCliente : '',
                                        value: idCliente ? idCliente : '',
                                      }}
                                      onSelect={(val) => {
                                        if (val.length > 1) {
                                          setIdCliente(val.value)
                                        }
                                      }}
                                      onChange={(value) => {
                                        const valor =
                                          value === null ? '' : value.value
                                        //console.lo(value);
                                        if (valor > 1) {
                                          setIdCliente(valor)
                                          setNomeCliente(value.labelshow)
                                          setVendedorP(
                                            value.dados.vendedorPadrao
                                              ? value.dados.vendedorPadrao.nome
                                              : '',
                                          )
                                          setIdVendedorP(
                                            value.dados.vendedorPadrao
                                              ? value.dados.vendedorPadrao.id
                                              : '',
                                          )
                                        } else {
                                          setIdCliente('')
                                          setNomeCliente('')
                                          setVendedorP('')
                                          setIdVendedorP('')
                                        }
                                      }}
                                    />
                                  </div>
                                </Grid>
                                {showTriangClient && (
                                  <>
                                    <Grid
                                      className="index2"
                                      item
                                      sm={12}
                                      lg={12}
                                      md={12}
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%',
                                      }}
                                    >
                                      <label>Cliente Entrega</label>

                                      <Async
                                        //onChange={e => (itemRequest.prazo_pagamentor = e.target.value)}
                                        loadOptions={
                                          debouncedLoadOptionsClientes
                                        }
                                        cacheOptions
                                        isClearable={true}
                                        noOptionsMessage={() =>
                                          'Nenhuma opção encontrada'
                                        }
                                        menuPortalTarget={document.body}
                                        styles={{
                                          menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 10000,
                                          }),
                                        }}
                                        placeholder="Clientes"
                                        onSelect={(e) => {
                                          alert(e.target.value);
                                          setIdClienteEntrega(e.target.value)
                                        }}
                                        value={{
                                          label: nomeClienteEntrega
                                            ? nomeClienteEntrega
                                            : '',
                                          value: idClienteEntrega
                                            ? idClienteEntrega
                                            : '',
                                        }}
                                        onChange={(value) => {

                                          const valor =
                                            value === null ? '' : value.value

                                          if (valor > 1) {
                                            setcnpjCliente(value.dados.cnpj)
                                            setcepCliente(
                                              value.dados.enderecoCep,
                                            )
                                            setIdClienteEntrega(valor)
                                            setNomeClienteEntrega(
                                              value.labelshow,
                                            )
                                          } else {
                                            setIdClienteEntrega('')
                                            setNomeClienteEntrega('')
                                          }
                                        }}
                                      />
                                    </Grid>
                                  </>
                                )}

                                <Grid
                                  className="index2"
                                  item
                                  sm={12}
                                  lg={12}
                                  md={12}
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                    zIndex: '98',
                                    height: '30%',
                                  }}
                                >
                                  <label>Vendedor*</label>
                                  <Autocomplete
                                    items={autoVendedor}
                                    wrapperStyle={{
                                      position: 'relative',
                                      zIndex: '40',
                                      display: 'inline-block',
                                    }}
                                    shouldItemRender={(item, value) =>
                                      item.NOME.toLowerCase().indexOf(
                                        value.toLowerCase(),
                                      ) > -1
                                    }
                                    getItemValue={(item) => {
                                      setIdVendedorP(item.NUMCAD1)
                                      return item.NOME
                                    }}
                                    renderItem={(item, isHighlighted) => (
                                      <div
                                        key={item.NUMCAD1}
                                        style={{
                                          background: isHighlighted
                                            ? 'lightgray'
                                            : 'white',
                                          width: '100%',
                                        }}
                                      >
                                        {item.NOME}
                                      </div>
                                    )}
                                    value={vendedorP}
                                    defaultValue={vendedorP}
                                    onChange={(e) =>
                                      setVendedorP(e.target.value)
                                    }
                                    onSelect={(val) => {
                                      setVendedorP(val)
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </CardContent>
                            <CardHeader
                              className={classes.header}
                              component={Typography}
                              title={'DEFINIÇÕES DE TRANSPORTE'}
                            />
                            <CardContent>
                              <Grid
                                container
                                spacing={2}
                                style={{ width: '100%', background: 'white' }}
                              >
                                <Grid
                                  className="index"
                                  item
                                  sm={12}
                                  lg={12}
                                  md={12}
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                  }}
                                >
                                  <div className="index">
                                    <label>Transportadora*</label>

                                    <Async
                                      //onChange={e => (itemRequest.prazo_pagamentor = e.target.value)}
                                      loadOptions={debouncedLoadOptionsTransp}
                                      cacheOptions
                                      isClearable={true}
                                      menuPortalTarget={document.body}
                                      menuPlacement="top"
                                      styles={{
                                        menuPortal: (base) => ({
                                          ...base,
                                          zIndex: 20000,
                                        }),
                                      }}
                                      noOptionsMessage={() =>
                                        'Nenhuma opção encontrada'
                                      }
                                      placeholder="Transportadora"
                                      value={{
                                        label: nomeTransp ? nomeTransp : '',
                                        value: idTransp ? idTransp : '',
                                      }}
                                      onSelect={(val) => {
                                        if (val.length > 1) {
                                          setIdTransp(val.value)
                                        }
                                      }}
                                      s
                                      onChange={(value) => {
                                        const valor =
                                          value === null ? '' : value.value
                                        if (valor > 1) {
                                          setIdTransp(valor)
                                          setNomeTransp(value.labelshow)
                                        } else {
                                          setIdTransp('')
                                          setNomeTransp('')
                                        }
                                      }}
                                    />
                                  </div>
                                </Grid>

                                <Grid
                                  className="index"
                                  item
                                  sm={12}
                                  lg={12}
                                  md={12}
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                  }}
                                >
                                  <div className="index">
                                    <label>Redespacho</label>

                                    <Async
                                      //onChange={e => (itemRequest.prazo_pagamentor = e.target.value)}
                                      loadOptions={debouncedLoadOptionsTransp}
                                      cacheOptions
                                      isClearable={true}
                                      menuPortalTarget={document.body}
                                      styles={{
                                        menuPortal: (base) => ({
                                          ...base,
                                          zIndex: 20001
                                        }),
                                      }}
                                      noOptionsMessage={() =>
                                        'Nenhuma opção encontrada'
                                      }
                                      placeholder="Transportadora"
                                      menuPlacement="top"

                                      value={{
                                        label: nomeTranspRedespacho
                                          ? nomeTranspRedespacho
                                          : '',
                                        value: idTranspRedespacho
                                          ? idTranspRedespacho
                                          : '',
                                      }}
                                      onSelect={(val) => {
                                        if (val.length > 1) {
                                          setIdTranspRedespacho(val.value)
                                        }
                                      }}
                                      onChange={(value) => {
                                        const valor =
                                          value === null ? '' : value.value
                                        if (valor > 1) {
                                          setIdTranspRedespacho(valor)
                                          setNomeTranspRedespacho(
                                            value.labelshow,
                                          )
                                        } else {
                                          setIdTranspRedespacho('')
                                          setNomeTranspRedespacho('')
                                        }
                                      }}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item xs={12} lg={8} md={8}>
                          <Card className={classes.card}>
                            <CardHeader
                              className={classes.header}
                              component={Typography}
                              title={'PEDIDOS'}
                            />
                            <CardContent>
                              <Grid
                                container
                                spacing={0}
                                style={{
                                  width: '100%',

                                }}
                              >
                                <Grid
                                  className="index2"
                                  item
                                  sm={12}
                                  lg={12}
                                  md={12}
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',

                                  }}
                                >
                                  <DataTable
                                    rows={listCart}
                                    rowHead={headRequest}
                                    title={''}
                                    titleNoData={''}
                                    searchInput={false}
                                    load={loading}
                                    maxHeight={'90%'}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12}>
                                  <label>Observações</label>

                                  <div className="input">
                                    <textarea
                                      rows="5"
                                      cols="40"
                                      name="obs"
                                      type="text"
                                      onChange={(e) => setObs(e.target.value)}
                                    />
                                  </div>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  lg={12}
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                  }}
                                >
                                  <label>* Campos obrigatórios</label>
                                </Grid>

                                <ButtonRequest
                                  bg="#ff5858"
                                  disabled={isRequest}
                                  onClick={cancelRequest}
                                >
                                  Cancelar
                                </ButtonRequest>

                                {(idCliente.length !== 0) &
                                (Object.values(validacao).filter(
                                  (array) => array.validacao == 0,
                                ).length ===
                                  0) &
                                (idTransp.length !== 0) &
                                (idVendedorP.length !== 0) & (!showTriangClient  || (showTriangClient && idClienteEntrega.length!==0)) ? (
                                  <ButtonRequest
                                    bg="#00acc1"
                                    disabled={isRequest}
                                    onClick={orderView}
                                  >
                                    Visualizar Pedidos
                                  </ButtonRequest>
                                ) : ''}
                                <br />
                                <br />
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </FormAuto>
                  </Typography>
                </div>
              </Pesquisa>

              <ToastContainer />
            </div>
          </FormAuto>
        </>
      )
    } else if (isFinishRequest) {
      return (
        <PaperStyle>
          <div className="div">
            <h1 style={{ marginTop: '18px' }}>Notificação do Cliente</h1>
            <FormAuto>
              <Grid container spacing={1}>
                <Grid className="index" item sm={12} lg={6} md={12}>
                  <label>Email de notificação</label>
                  <ReactMultiEmail
                    placeholder="Emails que receberam o pdf"
                    emails={emails}
                    onChange={(_emails) => {
                      setEmail(_emails)
                    }}
                    getLabel={(email, index, removeEmail) => {
                      return (
                        <div data-tag key={index}>
                          {email}
                          <span
                            data-tag-handle
                            onClick={() => removeEmail(index)}
                          >
                            ×
                          </span>
                        </div>
                      )
                    }}
                  />
                </Grid>
                <ButtonRequest
                  bg="#00acc1"
                  disabled={isRequest}
                  onClick={(e) => sendUserNotification(e)}
                >
                  Enviar Notificação
                </ButtonRequest>
              </Grid>
            </FormAuto>
          </div>

          <DataTable
            rows={listRequest}
            rowHead={rowHeadRequest}
            title={''}
            titleNoData={''}
            searchInput={false}
            load={loading}
          />

          <br />
          <br />
        </PaperStyle>
      )
    } else if (isViewRequest) {
      function ccyFormat(numero) {
        return (
          'R$ ' +
          numero
            .toFixed(2)
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+\,)/g, '$1.')
        )
      }

      function subtotal(items) {
        return items
          .map(({ VALOR_TOTAL }) => VALOR_TOTAL)
          .reduce((sum, i) => sum + i, 0)
      }

      function total() {
        var tot = 0
        itemSendRequest.map((item) => {
          tot = tot + subtotal(item.items)
        })
        return tot
      }

      return (
        <PaperStyle>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <StyledTableCellPrimary colSpan="6" align="center">
                    Pedido(s) {' de: ' + nomeCliente}
                  </StyledTableCellPrimary>
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Cliente</StyledTableCell>
                  <StyledTableCell align="center">Vendedor</StyledTableCell>
                  <StyledTableCell align="center">
                    Transportadora
                  </StyledTableCell>
                  <StyledTableCell align="center">Redespacho</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow key={itemSendRequest[0].index}>
                  <StyledTableCell>{nomeCliente}</StyledTableCell>
                  <StyledTableCell align="left">
                    {itemSendRequest[0].vendedor_id + '-' + vendedorP}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {itemSendRequest[0].transportadora +
                      '-' +
                      itemSendRequest[0].transportadora_nome}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {itemSendRequest[0].redespacho +
                      '-' +
                      itemSendRequest[0].redespacho_nome}
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <br></br>

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Código</StyledTableCell>
                  <StyledTableCell align="left">Item</StyledTableCell>
                  <StyledTableCell align="left">Grade</StyledTableCell>
                  <StyledTableCell align="right">Empresa</StyledTableCell>
                  <StyledTableCell align="right">Programação</StyledTableCell>
                  <StyledTableCell align="right">Quantidade</StyledTableCell>
                  <StyledTableCell align="right">
                    Valor Unitário
                  </StyledTableCell>
                  <StyledTableCell align="right">Valor Total</StyledTableCell>
                </TableRow>
              </TableHead>

              {itemSendRequest.map((item, index) => (
                <TableBody>
                  {item.items.map((row) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell>{row.ITEM_ID}</StyledTableCell>
                      <StyledTableCell align="left">
                        {row.ITEM_NOME}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.ITEM_GRADE}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.EMPRESA_APELIDO}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.TIPO_VENDA}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.QUANTIDADE + row.ITEM_UNIDADE}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {ccyFormat(row.VALOR_UNITARIO)}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {ccyFormat(row.VALOR_TOTAL)}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}

                  <TableRow>
                    <StyledTableCell rowSpan={6} colSpan={6} align="right" />
                    <StyledTableCell colSpan={1} align="right">
                      Subtotal
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {ccyFormat(subtotal(item.items))}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell colSpan={1} align="right">
                      Prazo de pagamento
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {item.prazo_pagamento}
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              ))}

              <TableRow>
                <StyledTableCell colSpan={7} align="right">
                  Total
                </StyledTableCell>
                <StyledTableCell align="right">
                  {ccyFormat(total())}
                </StyledTableCell>
              </TableRow>
            </Table>
          </TableContainer>
          <br />
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <StyledTableCell rowSpan="6" align="center">
                    Observações
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow key={itemSendRequest[0].index}>
                  <StyledTableCell align="left">
                    {itemSendRequest[0].observacoes}
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <br />
          <ButtonRequest
            bg="#00acc1"
            disabled={isRequest}
            onClick={orderConfirmation}
          >
            Confirmar Pedido
          </ButtonRequest>
          <ButtonRequest bg="#00acc1" onClick={orderViewCancel}>
            Voltar
          </ButtonRequest>
        </PaperStyle>
      )
    }
  }
}

FinalizaPedido.propTypes = {
  itemCart: PropTypes.arrayOf(PropTypes.object).isRequired,
  confirma: PropTypes.bool,
  Transp: PropTypes.arrayOf(PropTypes.object).isRequired,
  Clients: PropTypes.arrayOf(PropTypes.object).isRequired,
}
export default connect()(FinalizaPedido)
