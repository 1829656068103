import React from "react";

import { Container } from "./styles";
import LoginData from "../LoginData";

export default function Screen() {
  return (
    <Container>
      <LoginData />
    </Container>
  );
}
