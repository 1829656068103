import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Grid from "@material-ui/core/Grid";
import InputMask from "react-input-mask";
import { useForm } from "react-hook-form";
import Autocomplete from "react-autocomplete";
import { toast } from "react-toastify";
import { Form, ButtonStyled } from "./styles";
import {API} from "../../../config/api"

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: "80%",
    maxHeight: "80%",
    overflow: "scroll"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220
  },
  button: {
    border: 0,
    borderRadius: "20px",
    backgroundColor: "#00c156",
    color: "#fff",
    padding: "5px",
    cursor: "pointer",
    display: "flex",
    marginLeft: "auto",
    marginRight: "15px"
  }
}));

export default function ModalCreate() {
  // Token
  const token = sessionStorage.getItem('token');
  const email = sessionStorage.getItem('email');
  
  // Modal
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [required, setRequired] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  function mascaraCEP(cep){
    cep=cep.replace(/\D/g,"")
    cep=cep.replace(/^(\d{2})(\d)/,"$1.$2")
    cep=cep.replace(/\.(\d{3})(\d)/,".$1-$2")
    return cep
  }
  // react-hooks-form
  const { register, handleSubmit } = useForm();

  // Tipo Required
  const [tipoRequired, setTipoRequired] = useState(true);
  const handleRequiretipo = e => {
    if (e === "FISICA") {
      setTipoRequired(false);
      setRequired(false);

    } else {
      setTipoRequired(true);
      setRequired("required");
    }
  };
 
  // Submit
  const onSubmit = async data => {
    console.log(data);
    try {
      const {
        ativa,
        bloqueada,
        tipo,
        nome,
        apelido,
        cnpj,
        inscricaoEstadual,
        cpf,
        rg,
        enderecoLogradouro,
        enderecoNumero,
        enderecoComplemento,
        enderecoBairro,
        enderecoCidade,
        enderecoEstado,
        enderecoPais,
        enderecoCep,
        observacoes,
        vendedorPadraoId,
        vendedorPadraoNome
      } = data;
      console.log(data);
      const post_client = await axios.post(`${API.clientes}`,
              {
                ativa,
                bloqueada,
                tipo,
                nome,
                apelido,
                cnpj,
                inscricaoEstadual,
                cpf,
                rg,
                enderecoLogradouro,
                enderecoNumero,
                enderecoComplemento,
                enderecoBairro,
                enderecoCidade,
                enderecoEstado,
                enderecoPais,
                enderecoCep,
                observacoes,
                vendedorPadrao: {
                  id: vendedorPadraoId,
                  nome: vendedorPadraoNome
                }
              },
              {
                headers: {
                  "x-access-token": token
                }
              }
            );
//      console.log('111111');
  //          console.log(post_client);
 window.location.reload();      
    
     
    } catch (err) {
     
      toast.error(`Falha na criação, verifique seus dados.Erro: ${err.response.data.message}`);
    }
  };

  // AutoComplete
  const [auto, setAuto] = useState();
  let value = "";
  let valueId = "";

  const clientesext = JSON.parse(sessionStorage.getItem("clientes"));
  const perfil      = sessionStorage.getItem("perfil");

  if(perfil==='vendedor'){
    if(clientesext){
    value = clientesext && clientesext[0].vendedorPadrao === null ? "" : clientesext[0].vendedorPadrao.nome;
    valueId = clientesext && clientesext[0].vendedorPadrao === null ? "" : clientesext[0].vendedorPadrao.id;
    }else{value='';valueId=0;}
 }
 
  const [valueAutoId, setValueAutoId] = useState(value);
  const [valueAutoNome, setValueAutoNome] = useState(value);
  useEffect(() => {
    async function handleReq() {
      try {
        const response = await axios.get(
          `${API.vendedores}?email=${email}`,
          {
            headers: {
              "x-access-token": token
            }
          }
        );

        const list = response.data.data;
        setAuto(list);
      } catch (err) {
        toast.error("Erro ao listar vendedores");
      }
    }

    handleReq();
  }, []);

  const [logradouroApi, setLogradouro] = useState("");
  const [cidadeApi, setCidade] = useState("");
  const [bairroApi, setBairro] = useState("");
  const [estadoApi, setEstado] = useState("");
  const [complementoApi, setComplemento] = useState("");
  // Cep
  const handleCep = async e => {
    try {
      const response = await axios.get(
        `https://viacep.com.br/ws/${e.target.value}/json`
      );
      const {
        logradouro: logradouroApi,
        bairro: bairroApi,
        uf: ufApi,
        localidade: localidadeApi,
        complemento: complementoApi
      } = response.data;

      setLogradouro(logradouroApi);
      setBairro(bairroApi);
      setEstado(ufApi);
      setCidade(localidadeApi);
      setComplemento(complementoApi);
    } catch (err) {
      toast.error("Cep inválido");
    }
  };

  // Selects
  return (
    <div>
      <button className={classes.button} type="button" onClick={handleOpen}>
        <AddCircleOutlineIcon />
      </button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <input type="hidden" ref={register} name="id" />
              
                <Grid item xs={4} sm={4} lg={4}>
                  <div className="divCheck">
                    <h6>Ativa</h6>
                    <input
                      type="checkbox"
                      name="ativa"
                      ref={register}
                      checked
                      value={true}
                      readyonly
                    />
                  </div>
                </Grid>
                <Grid item xs={4} sm={4} lg={4}>
                  <div className="divCheck">
                    <h6>Bloqueada</h6>
                    <input
                      type="checkbox"
                      name="bloqueada"
                      ref={register}
                      value={false}
                      disabled
                    />
                  </div>
                </Grid>
                <Grid item xs={4} sm={4} lg={4}>
                  <div className="divCheck">
                    <h6>Cliente</h6>
                    <input
                      type="checkbox"
                      name="cliente"
                      ref={register}
                      checked
                      value={true}
                      readyonly
                    />
                  </div>
                </Grid>
               
                <Grid item xs={12} sm={12} lg={4}>
                  <div className="input">
                    <label>Tipo*</label>
                    <select
                      name="tipo"
                      required="required" 

                      ref={register}
                      onChange={e => handleRequiretipo(e.target.value)}
                    >
                      <option value={"JURIDICA"}>JURIDICO</option>
                      <option value={"FISICA"}>FISICA</option>
                    </select>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} lg={4}>
                  <div className="input">
                    <label>Nome*</label>
                    <input name="nome"                         
                           required="required" 
                           type="text" ref={register} />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} lg={4}>
                  <div className="input">
                    <label>Apelido*</label>
                    <input name="apelido" 
                           type="text" 
                           required="required" 
                           ref={register} />
                  </div>
                </Grid>
                {tipoRequired ? (
                  <>
                    <Grid item xs={12} sm={12} lg={4}>
                      <div className="input">
                        <label>Cnpj*</label>
                        <InputMask   maskChar=' ' mask="99.999.999/9999-99">
                          <input
                            name="cnpj"
                            type="text"
                            required= {(required)?required:"required"}
                            ref={register({
                              required: true,
                              min: 16,
                              max: 16
                            })}
                          />
                        </InputMask>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={4}>
                      <div className="input">
                        <label>Inscrição Estadual*</label>
                          <input
                            name="inscricaoEstadual"
                            type="text"
                            required= {(required)?required:"required"}
                            ref={register}
                          />
                      </div>
                    </Grid>
                    
                  </>
                ) : (
                  <>

                    <Grid item xs={12} sm={12} lg={4}>
                      <div className="input">
                        <label>RG*</label>
                          <input
                            name="rg"
                            type="text"
                            required= {(required)?required:"required"}
                            ref={register}
                          />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={4}>
                      <div className="input">
                        <label>Cpf*</label>
                        <InputMask mask="999.999.999-99">
                          <input name="cpf" type="text" ref={register} />
                        </InputMask>
                      </div>
                    </Grid>
                   
                   
                   
                  </>
                )}
                <Grid item xs={12} sm={12} lg={4}>
                  <div className="input">
                    <label>Cep*</label>
                    <InputMask mask="99999-999" onBlur={handleCep}>
                    <input
                      name="enderecoCep"
                      type="text"
                      required="required"
                     // onKeyDown={e=> mascaraCEP(e.target.value)}
                      ref={register}
                    />
                    </InputMask>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} lg={4}>
                  <div className="input">
                    <label>Endereço*</label>
                    <input
                      name="enderecoLogradouro"
                      type="text"
                      required="required" 
                      value={logradouroApi}
                      onChange={e => setLogradouro(e.target.value)}
                      ref={register}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} lg={4}>
                  <div className="input">
                    <label>Número*</label>
                    <input 
                          name="enderecoNumero" 
                          required="required" 
                          type="text" 
                          ref={register} />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} lg={4}>
                  <div className="input">
                    <label>Complemento</label>
                    <input
                      name="enderecoComplemento"
                      type="text"
                      value={complementoApi ? complementoApi : ""}
                      onChange={e => setComplemento(e.target.value)}
                      ref={register}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} lg={4}>
                  <div className="input">
                    <label>Bairro*</label>
                    <input
                      name="enderecoBairro"
                      type="text"
                      required="required" 
                      value={bairroApi ? bairroApi : ""}
                      onChange={e => setBairro(e.target.value)}
                      ref={register}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} lg={4}>
                  <div className="input">
                    <label>Cidade*</label>
                    <input
                      name="enderecoCidade"
                      type="text"
                      required="required" 
                      value={cidadeApi ? cidadeApi : ""}
                      onChange={e => setCidade(e.target.value)}
                      ref={register}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} lg={4}>
                  <div className="input">
                    <label>Estado*</label>
                    <input
                      name="enderecoEstado"
                      type="text"
                      required="required" 
                      value={estadoApi ? estadoApi : ""}
                      onChange={e => setEstado(e.target.value)}
                      ref={register}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} lg={4}>
                  <div className="input">
                    <label>País*</label>
                    <input 
                          name="enderecoPais" 
                          value="Brasil" 
                          required="required" 
                          type="text" 
                          ref={register} />
                  </div>
                </Grid>
             
                <Grid item xs={12} sm={12} lg={4}>
                  <div className="input">
                    <label>Vendedor*</label>
                    <input
                      name="vendedorPadraoId"
                      type="hidden"
                      required="required" 
                      ref={register}
                      defaultValue={valueAutoId}
                    />
                    <input
                      name="vendedorPadraoNome"
                      type="hidden"
                      ref={register}
                    />
                    <Autocomplete
                      items={auto}
                      wrapperStyle={{ position: 'relative',zIndex:'2', display: 'inline-block' }}
                      shouldItemRender={(item, value) =>
                        item.NOME.toLowerCase().indexOf(value.toLowerCase()) >
                        -1
                      }
                      getItemValue={item => {
                        setValueAutoId(item.NUMCAD1);
                        return item.NOME;
                      }}
                      renderItem={(item, isHighlighted) => (
                        <div
                          key={item.NUMCAD1}
                          style={{
                            background: isHighlighted ? "lightgray" : "white",
                            width: "100%",
                            zIndex:"50",
                            position:"relative"
                            
                          }}
                        >
                          {item.NOME}
                        </div>
                      )}
                      value={valueAutoNome}
                      required="required" 

                      onChange={e => setValueAutoNome(e.target.value)}
                      onSelect={val => setValueAutoNome(val)}
                    />
                  </div>
                </Grid>
              
                <Grid item xs={12} sm={12} lg={8}>
                  <div className="input">
                    <label>Observações</label>
                
                    <textarea
                       rows="5" cols="63"
                      name="observacoes"
                      type="text"
                      ref={register}
                    />
                  </div>
                  <span>* Campos obrigatórios</span>

                </Grid>
              </Grid>
              <ButtonStyled  variant="contained" color="primary">
                Criar
              </ButtonStyled>
            </Form>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
