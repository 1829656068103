import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Grid from "@material-ui/core/Grid";
import { useForm } from "react-hook-form";
import { Form, ButtonStyled } from "./styles";
import { toast } from "react-toastify";
import Autocomplete from "react-autocomplete";
import {API} from "../../../config/api"


const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: "80%",
    height: "80%",
    overflow: "scroll",
    maxHeight: "80%"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  button: {
    border: 0,
    borderRadius: "20px",
    backgroundColor: "#00c156",
    color: "#fff",
    padding: "5px",
    cursor: "pointer",
    display: "flex",
    marginLeft: "auto",
    marginRight: "15px"
  }
}));

export default function ModalCreateAtribuidas() {
  // Token

  const token = sessionStorage.getItem('token');
  const email = sessionStorage.getItem('email');
  const perfil = sessionStorage.getItem('perfil');


  // Modal
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [autoNome, setAutoNome] = useState("");
  const [autoNomeRegra, setAutoNomeRegra] = useState("");
  const [autoNomeListaPrecos, setAutoNomeListaPrecos] = useState("");

  const [idEmpresa, setIdEmpresa] = useState("");
  const [autoListEmpresa, setAutoListEmpresa] = useState([]);
  
  const [idRegraComercial, setIdRegraComercial] = useState("");
  const [autoListRegraComercial, setAutoListRegraComercial] = useState([]);

  const [idListaPrecos, setIdListaPrecos] = useState("");
  const [autoListPrecos, setAutoListPrecos] = useState([]);

  // Auto
  useEffect(() => {

    const listEmpresa = async () => {
      try {
        const response = await axios.get(
          `${API.empresa}`,
  
          {
            headers: {
              "x-access-token": token
            }
          }
        );
  
        const listData = response.data.data;
        setAutoListEmpresa(listData);
      } catch (err) {
        console.log(err);
      }
    };

    const listRegraComercial = async () => {
      try {
        const response = await axios.get(
          `${API.regras}?email=${email}`,
  
          {
            headers: {
              "x-access-token": token
            }
          }
        );
  
        const listData = response.data.data;
        setAutoListRegraComercial(listData);
      } catch (err) {
        console.log(err);
      }
    };


    const listPrecos = async () => {
      try {
        const response = await axios.get(
          `${API.listaprecos}?email=${email}`,
  
          {
            headers: {
              "x-access-token": token
            }
          }
        );
  
        const listData = response.data.data;
        setAutoListPrecos(listData);
      } catch (err) {
        console.log(err);
      }
    };
    listPrecos();
    listEmpresa();
    listRegraComercial();
   
  }, []);

  // react-hooks-form
  const { register, handleSubmit } = useForm();
  // Submit
  const onSubmit = async data => {
    try {
      await axios.post(
        `${API.regrasatribuidas}?email=${email}`,
        {        
          REGRAS_ATRIBUIDAS_REGRAS_COMERCIAIS_ID: idRegraComercial,
          REGRAS_ATRIBUIDAS_EMPRESA_ID: idEmpresa,
          REGRAS_ATRIBUIDAS_VALOR: data.valor,
          REGRAS_ATRIBUIDAS_LISTA_PRECOS : idListaPrecos
        },
        {
          headers: {
            "x-access-token": token
          }
        }
      );
      toast.success("Regra criada com sucesso");
      window.location.reload();
    } catch (err) {
      toast.error("Verifique todos os campos");
    }
  };

  return (
    <div>
      <button className={classes.button} type="button" onClick={handleOpen}>
        <AddCircleOutlineIcon />
      </button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} lg={12}>
                  <div className="input">
                    <label>Empresa</label>
                    <Autocomplete
                      renderInput={props => (
                        <input {...props} autoComplete={false} required />
                      )}
                      items={autoListEmpresa}
                      shouldItemRender={(item, value) =>
                        item.EMPRESA_NOME.toLowerCase().indexOf(value.toLowerCase()) >
                        -1
                      }
                      getItemValue={item => {
                        setIdEmpresa(item.EMPRESA_ID);
                        return item.EMPRESA_NOME;
                      }}
                      menuStyle={{
                        borderRadius: "3px",
                        boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
                        background: "rgba(255, 255, 255, 0.9)",
                        padding: "2px 0",
                        fontSize: "90%",
                        position: "fixed",
                        overflow: "auto",
                        maxHeight: "50%",
                        zIndex: "400"
                      }}
                      renderItem={(item, isHighlighted) => (
                        <div
                          key={item.EMPRESA_ID}
                          style={{
                            background: isHighlighted ? "lightgray" : "white",
                            width: "100%"
                          }}
                        >
                          {item.EMPRESA_NOME}
                        </div>
                      )}
                      value={autoNome}
                      onChange={e => setAutoNome(e.target.value)}
                      onSelect={val => setAutoNome(val)}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                <div className="input">
                    <label>REGRA COMERCIAL</label>
                    <Autocomplete
                      renderInput={props => (
                        <input {...props} autoComplete={false} required />
                      )}
                      items={autoListRegraComercial}
                      shouldItemRender={(item, value) =>
                        item.REGRAS_COMERCIAIS_INFO.toLowerCase().indexOf(value.toLowerCase()) >
                        -1
                      }
                      getItemValue={item => {
                        setIdRegraComercial(item.REGRAS_COMERCIAIS_ID);
                        return item.REGRAS_COMERCIAIS_INFO;
                      }}
                      menuStyle={{
                        borderRadius: "3px",
                        boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
                        background: "rgba(255, 255, 255, 0.9)",
                        padding: "2px 0",
                        fontSize: "90%",
                        position: "fixed",
                        overflow: "auto",
                        maxHeight: "50%",
                        zIndex: "400"
                      }}
                      renderItem={(item, isHighlighted) => (
                        <div
                          key={item.REGRAS_COMERCIAIS_ID}
                          style={{
                            background: isHighlighted ? "lightgray" : "white",
                            width: "100%"
                          }}
                        >
                          {item.REGRAS_COMERCIAIS_INFO}
                        </div>
                      )}
                      value={autoNomeRegra}
                      onChange={e => setAutoNomeRegra(e.target.value)}
                      onSelect={val => setAutoNomeRegra(val)}
                    />
                  </div>
                </Grid>
               
                <Grid item xs={12} sm={12} lg={12}>
                <div className="input">
                    <label>LISTA PREÇOS</label>
                    <Autocomplete
                      renderInput={props => (
                        <input {...props} autoComplete={false} required />
                      )}
                      items={autoListPrecos}
                      shouldItemRender={(item, value) =>
                        item.LISTA_PRECOS_NOME.toLowerCase().indexOf(value.toLowerCase()) >
                        -1
                      }
                      getItemValue={item => {
                        setIdListaPrecos(item.LISTA_PRECOS_ID);
                        return item.LISTA_PRECOS_NOME;
                      }}
                      menuStyle={{
                        borderRadius: "3px",
                        boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
                        background: "rgba(255, 255, 255, 0.9)",
                        padding: "2px 0",
                        fontSize: "90%",
                        position: "fixed",
                        overflow: "auto",
                        maxHeight: "50%",
                        zIndex: "400"
                      }}
                      renderItem={(item, isHighlighted) => (
                        <div
                          key={item.LISTA_PRECOS_ID}
                          style={{
                            background: isHighlighted ? "lightgray" : "white",
                            width: "100%"
                          }}
                        >
                          {item.LISTA_PRECOS_NOME}
                        </div>
                      )}
                      value={autoNomeListaPrecos}
                      onChange={e => setAutoNomeListaPrecos(e.target.value)}
                      onSelect={val => setAutoNomeListaPrecos(val)}
                    />
                  </div>
                </Grid>
               
               
               
               
                <Grid item xs={6} sm={12} lg={6}>
                  <div className="input">
                    <label>Valor</label>
                    <input name="valor" type="text" ref={register} />
                  </div>
                </Grid>
              </Grid>
              <ButtonStyled variant="contained" color="primary">
                Criar
              </ButtonStyled>
            </Form>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
